import './neb-documents-info-details';
import '../../../neb-material-design/src/components/neb-md-slider';
import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_BLACK,
  CSS_COLOR_WHITE,
} from '../../../neb-styles/neb-variables';
import { clamp } from '../../../neb-utils/utils';
import { adjustCanvasImageToContainer } from '../nebImageViewerUtil';

export const ELEMENTS = {
  canvas: {
    id: 'canvas',
  },
  slider: {
    id: 'input-range',
  },
  sliderTitle: {
    id: 'input-range-title',
  },
  documentsInfoDetails: {
    id: 'documents-info-details',
  },
};
const relativeImageRowHeight = 1;
const ZOOM_CONTROL_HEIGHT = 50;
const ZOOM_CONTROL_HEIGHT_SMALL_MEDIUM = 90;
const DOCUMENT_INFO_DETAILS_HEIGHT = 180;
const DOCUMENT_INFO_DETAILS_HEIGHT_HIDDEN_SPACE = 20;
const debounceDelay = 250;
const IMAGE_SMALL_HORIZONTAL_PADDING = 22;
const IMAGE_SMALL_HORIZONTAL_PADDING_CSS = css`
  ${IMAGE_SMALL_HORIZONTAL_PADDING}px
`;

const ID_CONTAINER = 'container';
class NebImageViewer extends LitElement {
  static get properties() {
    return {
      __zoom: Number,
      __panPos: Object,
      minZoom: Number,
      maxZoom: Number,
      step: Number,
      src: String,
      small: {
        type: Boolean,
        reflect: true,
      },
      medium: {
        type: Boolean,
        reflect: true,
      },
      imageScaled: Boolean,
      shouldRenderCanvas: Boolean,
      fullScreen: Boolean,
      hideFooter: Boolean,
      model: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__resetProps();

    this.__adjustCanvasImageToContainer = (
      containerWidth,
      containerHeight,
      imageWidth,
      imageHeight,
    ) =>
      adjustCanvasImageToContainer(
        containerWidth,
        containerHeight,
        imageWidth,
        imageHeight,
        this,
      );

    this.src = '';
    this.minZoom = 1;
    this.maxZoom = 2;
    this.step = 0.01;
    this.identity();
    this.resizeDebounceId = null;
    this.fullScreen = false;
    this.hideFooter = false;

    this.onGetDocumentContentSize = () => {};

    this.model = {
      id: null,
      name: '',
      note: '',
      date: null,
      file: null,
      uploadDate: null,
    };
  }

  __initHandlers() {
    this.__handlers = {
      zoom: data => {
        this.__zoom = data.value;

        this.__boundPosition();

        this.__redraw();
      },
      dragStart: e => {
        this.__startMousePos = this.getMousePosition(e);
        this.__dragging = true;
      },
      dragEnd: () => {
        this.__mouseOffset = {
          x: 0,
          y: 0,
        };

        this.__startMousePos = {
          x: 0,
          y: 0,
        };

        this.__startPanPos = { ...this.__panPos };
        this.__dragging = false;
      },
      dragMove: e => {
        if (!this.__dragging) {
          return false;
        }

        const currentPos = this.getMousePosition(e);
        this.__mouseOffset = {
          x: currentPos.x - this.__startMousePos.x,
          y: currentPos.y - this.__startMousePos.y,
        };

        this.__boundPosition();

        this.__redraw();

        e.preventDefault();
        return true;
      },
      adjustCanvas: () => {
        this.__resizeDebounce();
      },
    };
  }

  __getDocumentInfoDetailsHeight() {
    return this.fullScreen
      ? DOCUMENT_INFO_DETAILS_HEIGHT_HIDDEN_SPACE
      : DOCUMENT_INFO_DETAILS_HEIGHT;
  }

  __getContainerDimensions() {
    const size = this.onGetDocumentContentSize();

    if (this.small && size.width) {
      size.width -= IMAGE_SMALL_HORIZONTAL_PADDING * 2;
    }

    return size;
  }

  __resizeDebounce() {
    if (this.resizeDebounceId) {
      clearTimeout(this.resizeDebounceId);
    }

    this.resizeDebounceId = setTimeout(
      () => this.__adjustCanvasOnResize(),
      debounceDelay,
    );
  }

  __adjustCanvasOnResize() {
    this.__resetScalingProps();

    this.__defineLoadEventOnResize();

    this.src = this.__image.src.slice();
  }

  __defineLoadEventOnResize() {
    // Here 'load' is added as an event to fix a Safari bug.
    // The data is reset to prevent Safari from only
    // calling load once.
    this.__image.addEventListener('load', this.__adjustImageOnResize());
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('mouseup', this.__handlers.dragEnd);
    document.addEventListener('touchend', this.__handlers.dragEnd);
    window.addEventListener('resize', this.__handlers.adjustCanvas);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mouseup', this.__handlers.dragEnd);
    document.removeEventListener('touchend', this.__handlers.dragEnd);
    window.removeEventListener('resize', this.__handlers.adjustCanvas);
  }

  identity() {
    this.__dragging = false;
    this.__zoom = this.minZoom;
    this.__panPos = {
      x: 0,
      y: 0,
    };

    this.__startPanPos = {
      x: 0,
      y: 0,
    };

    this.__mouseOffset = {
      x: 0,
      y: 0,
    };

    this.__startMousePos = {
      x: 0,
      y: 0,
    };

    if (this.__ctx) {
      this.__redraw();
    }
  }

  __boundPosition() {
    const scaledMouseOffset = this.getMouseOffsetForCanvas();
    const pos = {
      x: this.__startPanPos.x + scaledMouseOffset.x,
      y: this.__startPanPos.y + scaledMouseOffset.y,
    };
    const scaledImageDims = {
      width: this.adjustedImageWidth * this.__zoom,
      height: this.adjustedImageHeight * this.__zoom,
    };
    const maxDiff = {
      width: (scaledImageDims.width - this.adjustedImageWidth) / 2,
      height: (scaledImageDims.height - this.adjustedImageHeight) / 2,
    };
    this.__panPos.x = clamp(pos.x, -maxDiff.width, maxDiff.width);
    this.__panPos.y = clamp(pos.y, -maxDiff.height, maxDiff.height);

    if (!this.__dragging) {
      this.__startPanPos = { ...this.__panPos };
    }
  }

  __getFooterHeight() {
    if (this.small || this.medium) {
      return (
        ZOOM_CONTROL_HEIGHT_SMALL_MEDIUM + this.__getDocumentInfoDetailsHeight()
      );
    }

    return ZOOM_CONTROL_HEIGHT + this.__getDocumentInfoDetailsHeight();
  }

  getMouseOffsetForCanvas() {
    const rect = this.__elements.canvas.getBoundingClientRect();

    const scaledMouseFactor = {
      width: this.adjustedImageWidth / rect.width,
      height: this.adjustedImageHeight / rect.height,
    };
    return {
      x: this.__mouseOffset.x * scaledMouseFactor.width,
      y: this.__mouseOffset.y * scaledMouseFactor.height,
    };
  }

  getMousePosition(e) {
    const target = e.currentTarget;
    const rect = target.getBoundingClientRect();
    const isTouch = e.type.includes('touch');
    const clientPos = {
      x: isTouch ? e.touches[0].clientX : e.clientX,
      y: isTouch ? e.touches[0].clientY : e.clientY,
    };
    return {
      x: clientPos.x - rect.x,
      y: clientPos.y - rect.y,
    };
  }

  __drawCanvasOnImageSelect() {
    this.__elements = {
      canvas: this.shadowRoot.getElementById(ELEMENTS.canvas.id),
    };

    this.__elements.canvas.width = this.adjustedImageWidth;
    this.__elements.canvas.height = this.adjustedImageHeight;
    this.requestUpdate();
    this.updateComplete.then(() => {
      this.__ctx = this.__elements.canvas.getContext('2d');

      this.__boundPosition();

      this.__redraw();
    });
  }

  __resetProps() {
    this.__resetScalingProps();

    this.__image = new Image();
    this.__elements = null;
    this.__ctx = null;
  }

  __resetScalingProps() {
    this.shouldRenderCanvas = false;
    this.imageScaled = false;
    this.adjustedImageWidth = null;
    this.adjustedImageHeight = null;
  }

  __scaleImage() {
    this.__image.onload = () => {
      this.__adjustImageOnResize();
    };

    this.__image.src = this.src;
  }

  __adjustImageOnResize() {
    const size = this.__getContainerDimensions();

    this.__calculateCanvasDimensions(size);

    this.adjustedImageWidth = size.width;
    this.adjustedImageHeight = size.height;
    this.imageScaled = !!this.adjustedImageWidth && !!this.adjustedImageHeight;
  }

  updated(changedProps) {
    const canvasReset =
      !this.shouldRenderCanvas &&
      !this.imageScaled &&
      !this.adjustedImageWidth &&
      !this.adjustedImageHeight;
    const hasSrc = !!this.src;
    const canvasRendered =
      changedProps.has('shouldRenderCanvas') && this.shouldRenderCanvas;
    const imageScaled = changedProps.has('imageScaled') && this.imageScaled;

    if (changedProps.has('src')) {
      this.__resetProps();

      this.requestUpdate();
    } else if (hasSrc && canvasReset) {
      this.__scaleImage();
    } else if (imageScaled) {
      this.shouldRenderCanvas = true;
    } else if (canvasRendered) {
      this.__drawCanvasOnImageSelect();
    }
  }

  __redraw() {
    const width = this.adjustedImageWidth;
    const height = this.adjustedImageHeight;
    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const pos = this.__panPos;
    const clearDim = this.__elements
      ? {
          width: this.__elements.canvas.width,
          height: this.__elements.canvas.height,
        }
      : {
          width: 0,
          height: 0,
        };

    this.__ctx.save();

    this.__ctx.clearRect(0, 0, clearDim.width, clearDim.height);

    this.__ctx.translate(halfWidth + pos.x, halfHeight + pos.y);

    this.__ctx.scale(this.__zoom, this.__zoom);

    this.__ctx.drawImage(this.__image, -halfWidth, -halfHeight, width, height);

    this.__ctx.restore();
  }

  __calculateCanvasDimensions(containerDimensions) {
    const NO_CANVAS_DIMENSIONS = {
      width: 0,
      height: 0,
    };

    if (
      !containerDimensions ||
      !containerDimensions.width ||
      !containerDimensions.height
    ) {
      return NO_CANVAS_DIMENSIONS;
    }

    const containerWidth = containerDimensions.width;

    let containerHeight = containerDimensions.height;
    if (!containerWidth || !containerHeight) return NO_CANVAS_DIMENSIONS;
    containerHeight = parseInt(
      containerHeight * relativeImageRowHeight - this.__getFooterHeight(),
      10,
    );

    const imageWidth = this.__image.width;
    const imageHeight = this.__image.height;
    return this.__adjustCanvasImageToContainer(
      containerWidth,
      containerHeight,
      imageWidth,
      imageHeight,
    );
  }

  __renderStyles(containerDimensions) {
    const canvasDimensions = this.__calculateCanvasDimensions(
      containerDimensions,
    );

    return html`
      <style>
        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }

        :host {
          display: flex;
          width: 100%;
          height: 100%;
          border: 0 solid black;
          box-sizing: border-box;
        }

        :host([small]) {
          height: fit-content;
          padding: 0 ${IMAGE_SMALL_HORIZONTAL_PADDING_CSS};
        }

        .container {
          display: grid;
          grid-template-columns: auto;
          grid-template-rows: auto ${ZOOM_CONTROL_HEIGHT}px ${
              this.__getDocumentInfoDetailsHeight()
            }px;
          width: 100%;
          align-content: flex-start;
          align-items: center;
        }

        :host([small]) .container,
        :host([medium]) .container {
          grid-template-rows: auto ${ZOOM_CONTROL_HEIGHT_SMALL_MEDIUM}px ${
              this.__getDocumentInfoDetailsHeight()
            }px;
        }

        .canvas-container {
          display: inline-block;
          overflow: hidden;
          width: max-content;
          justify-self: center;
        }

        .item-label {
          margin-right: ${CSS_SPACING};
          align-self: center;
          color: ${this.fullScreen ? CSS_COLOR_WHITE : CSS_COLOR_BLACK};
        }

        .item-canvas {
          height: ${canvasDimensions.height || '100%'};
          width: 100%;
        }

        .item-canvas[moveable] {
          cursor: move;
        }

        :host([small]) .item-instructions,
        :host([medium]) .item-instructions {
          width: 100%;
          padding-bottom: 10px;
        }

        .item-instructions {
          margin-left: auto;
          color: ${CSS_COLOR_GREY_1};
        }

        :host([small]) .slider,
        :host([medium]) .slider {
          width: 100%;
        }

        .slider {
          width: 232px;
        }

        .zoom-container {
          display: flex;
        }

        .zoom-spacer {
          width: 15px;
        }

        .zoom-input-layout {
          display: grid;
          grid-template: auto / max-content max-content;
          grid-column-gap: 10px;
          justify-content: space-between;
          align-items: center;
          width: ${canvasDimensions.width || 'auto'};
          justify-self: center;
        }

        :host([small]) .zoom-input-layout,
        :host([medium]) .zoom-input-layout {
          grid-template: 1fr / 1fr;
          width: 100%;
        }

        .footer {
          width: 100%;
        }
      </style>
    `;
  }

  __renderFooter() {
    return !this.hideFooter && !this.fullScreen
      ? html`
          <div class="footer">
            <neb-documents-info-details
              id="${ELEMENTS.documentsInfoDetails.id}"
              .model="${this.model}"
            ></neb-documents-info-details>
          </div>
        `
      : html``;
  }

  render() {
    return this.shouldRenderCanvas
      ? html`
          ${this.__renderStyles(this.__getContainerDimensions())}

          <div id="${ID_CONTAINER}" class="container">
            <div class="canvas-container">
              <canvas
                id="${ELEMENTS.canvas.id}"
                class="item-canvas"
                ?moveable="${this.__zoom > this.minZoom}"
                @mousedown="${this.__handlers.dragStart}"
                @mousemove="${this.__handlers.dragMove}"
                @touchstart="${this.__handlers.dragStart}"
                @touchmove="${this.__handlers.dragMove}"
              ></canvas>
            </div>

            <div class="zoom-input-layout">
              <div class="zoom-container">
                <label id="${ELEMENTS.sliderTitle.id}" class="item-label"
                  >Zoom</label
                >
                <neb-md-slider
                  id="${ELEMENTS.slider.id}"
                  class="slider"
                  min="${this.minZoom}"
                  max="${this.maxZoom}"
                  interval="0.01"
                  value="${this.__zoom}"
                  .onInput="${this.__handlers.zoom}"
                ></neb-md-slider>
                <div class="zoom-spacer"></div>
              </div>

              <span class="item-instructions">Drag to reposition photo</span>
            </div>

            ${this.__renderFooter()}
          </div>
        `
      : html`
          <div></div>
        `;
  }
}
customElements.define('neb-image-viewer', NebImageViewer);
