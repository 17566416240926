import { css, html } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';

import '../../misc/neb-icon';
import '../../../../packages/neb-lit-components/src/components/encounter/neb-encounter-summary';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../../packages/neb-lit-components/src/components/forms/neb-form-patient-insurance-edit';
import '../../pages/documents/neb-documents-tile-view';
import '../../pages/documents/neb-page-document-viewer';
import '../../pages/documents/neb-documents-filters';

import {
  getDocSrc,
  getDocumentsWithThumbnails,
} from '../../../../packages/neb-api-client/src/document-api-client';
import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import { objToName } from '../../../../packages/neb-utils/formatters';
import { formatEncounterSummaryData } from '../../../../packages/neb-utils/neb-encounters-util';
import {
  CSS_BORDER_GREY_2,
  CSS_COLOR_BLACK,
  CSS_COLOR_ERROR,
  CSS_COLOR_GREY_1,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_SPACING_ROW_LARGE,
  CSS_SMALL_SPACING,
} from '../../../styles';

import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-text';

import { buildEncounterDetails } from './claim-errors-utils';

const NAME_OPTS = {
  reverse: true,
  middleInitial: true,
  preferred: true,
};

export const ELEMENTS = {
  cancelButton: { id: 'cancel-button' },
  claimInfo: { id: 'claim-info' },
  form: { id: 'form' },
  header: { id: 'header' },
  leftPanel: { id: 'left-panel' },
  claimNumberHeader: { id: 'claim-header-number' },
  patientName: { id: 'patient-name' },
  serviceDate: { id: 'service-date' },
  provider: { id: 'provider' },
  payer: { id: 'payer' },
  lastUpdated: { id: 'last-updated' },
  errorTable: { id: 'error-table' },
  table: { id: 'table' },
  warningIcon: { id: 'warning-icon' },
  leftPanelTabs: { id: 'left-panel-tabs' },
  encountersSelect: { id: 'encounters-select' },
  encounterSummary: { id: 'encounter-summary' },
  documentsTileView: { id: 'documents-tile-view' },
  documentViewer: { id: 'document-viewer' },
  documentsFilters: { id: 'documents-filters' },
};

export const LOADING_DOCUMENTS_ERROR = 'Error loading documents';

export class NebOverlayClaimError extends Overlay {
  static get properties() {
    return {
      __selectedLeftPanelTab: String,
      __encounters: Array,
      __encountersDetails: Array,
      __summaryModel: Object,
      __selectedEncounter: Object,
      __filters: Object,
      __tags: Object,
      __documents: Array,
      __originalDocuments: Array,
      __hasDocumentsTabFF: Boolean,
      __selectedDocument: Object,
      __imageSrcSpec: Object,

      widthRatio: String,
    };
  }

  initState() {
    super.initState();

    this.__selectedLeftPanelTab = 'encounter';
    this.__encounters = [];
    this.__encountersDetails = [];
    this.__summaryModel = {};
    this.__selectedEncounter = {};
    this.__filters = {};
    this.__documents = [];
    this.__tags = {};
    this.__hasDocumentsTabFF = false;
    this.__selectedDocument = null;
    this.__imageSrcSpec = null;

    this.__leftPanelTabs = [
      { id: 'encounter', label: 'Encounter', disabled: false },
      { id: 'documents', label: 'Documents', disabled: false },
    ];

    this.widthRatio = '50/50';

    this.model = {
      claimNumber: '',
      patient: {},
      serviceDate: '',
      provider: {},
      payer: '',
      errorDescriptions: '',
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      onChangeEncounter: ({ event, value, index }) => {
        if (event === 'select') {
          this.__selectedEncounter = value;
          this.__setSummaryModel(this.__encountersDetails[index]);
        }
      },
      selectLeftPanelTab: selectedTab => {
        this.__selectedLeftPanelTab = selectedTab;
        this.__clearSelectedDocument();
        this.__filterDocuments({});
      },
      selectDocument: async doc => {
        const docSrc = await getDocSrc(doc);

        this.__imageSrcSpec = {
          id: doc.id,
          mimeType: doc.mimeType,
          src: docSrc,
        };

        this.__selectedDocument = doc;
      },
      closeDocument: () => {
        this.__clearSelectedDocument();
        this.__filterDocuments(this.__filters);
      },
      onFilterDocuments: filters => this.__filterDocuments(filters),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }

        .claim-info {
          padding: 0 ${CSS_SPACING} ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
          margin-top: ${CSS_SPACING};
        }

        .claim-info-container {
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
          display: flex;
          margin-bottom: ${CSS_SPACING};
          padding: ${CSS_SPACING};
        }

        .descriptions {
          padding: ${CSS_SMALL_SPACING} ${CSS_SPACING} 0;
        }

        .field {
          width: 50%;
        }

        .form {
          padding: 0 ${CSS_SPACING};
        }

        .header {
          display: flex;
          justify-content: space-between;
          padding: ${CSS_SPACING};
        }

        .header-text {
          display: flex;
          gap: 20px;
        }

        .header-title {
          font-size: 16px;
          font-weight: bold;
          color: ${CSS_COLOR_BLACK};
        }

        .ellipse-field {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .panels {
          display: flex;
          height: 100%;
          border-top: ${CSS_BORDER_GREY_2};
          min-height: 0;
        }

        .label {
          font-weight: bold;
        }

        .left-panel {
          border-right: ${CSS_BORDER_GREY_2};
          overflow-y: auto;
          height: auto;
          display: flex;
          flex-direction: column;
        }

        .left-panel-tabs {
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 1;
          padding-top: 15px;
          min-height: 50px;
        }

        .left-panel-content {
          flex-grow: 1;
          overflow-y: auto;
        }

        .right-panel {
          display: flex;
          flex-direction: column;
        }

        .error-table {
          border: 1px solid ${CSS_COLOR_GREY_1};
          border-bottom: none;
        }

        .error-header {
          display: flex;
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
          color: ${CSS_COLOR_ERROR};
          font-weight: bold;
          font-size: ${CSS_FONT_SIZE_HEADER};
        }

        .cell {
          padding: ${CSS_SPACING_ROW};
          border-bottom: 1px solid ${CSS_COLOR_GREY_1};
        }

        .icon-warning {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_ERROR};
          margin-right: ${CSS_SPACING_ROW_LARGE};
        }

        .encounter-summary {
          overflow: auto;
          padding: 0 ${CSS_SPACING};
        }

        .service-date-select {
          display: flex;
          margin: 0 0 ${CSS_SMALL_SPACING} 0;
          padding: ${CSS_SMALL_SPACING} ${CSS_SPACING} 0;
        }

        neb-page-document-viewer {
          padding: ${CSS_SPACING};
          overflow: hidden;
          display: flex;
          flex-direction: column;
        }

        neb-documents-tile-view {
          padding: 0 ${CSS_SMALL_SPACING} ${CSS_SMALL_SPACING};
        }

        .document-container {
          display: flex;
          flex-direction: column;
        }

        .documents-filters {
          position: sticky;
          top: 50px;
          z-index: 1;
          background-color: white;
        }
      `,
    ];
  }

  async connectedCallback() {
    this.__hasDocumentsTabFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.EGG_CLAIMS_RESOLUTION_DOCUMENTS_TAB,
    );

    super.connectedCallback();
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      const {
        encounters,
        encountersDetails,
        selectedEncounter,
        selectedEncounterDetails,
      } = await buildEncounterDetails({
        patientId: this.model.patient.id,
        invoiceId: this.model.invoiceId,
      });

      this.__encounters = encounters;
      this.__selectedEncounter = selectedEncounter;
      this.__encountersDetails = encountersDetails;

      this.__setSummaryModel(selectedEncounterDetails);
      this.__getDocuments({});
    }

    super.updated();
  }

  __filterDocuments(filters) {
    this.__filters = filters;
    const { search = '', tags = [] } = filters;

    let documents = this.__originalDocuments || [];

    if (search) {
      documents = documents.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    if (tags.length) {
      const documentIds = tags.map(({ documentId }) => documentId);
      documents = documents.filter(({ id }) => documentIds.includes(id));
    }

    this.__documents = documents;
  }

  __setSummaryModel({ encounter, chartNotes }) {
    this.__summaryModel = formatEncounterSummaryData({
      encounter,
      notes: chartNotes,
      patient: this.model.patient,
      state: store.getState(),
    });
  }

  async __getDocuments({ search: searchTerms }) {
    try {
      const { data: documents } = await getDocumentsWithThumbnails({
        patientId: this.model.patientId,
        limit: 100,
        offset: 0,
        optOutLoadingIndicator: true,
        ...(searchTerms ? { searchTerms } : {}),
      });

      this.__documents = documents;
      this.__originalDocuments = documents;
      this.__tags = this.__buildTags(documents);
    } catch (error) {
      store.dispatch(openError(LOADING_DOCUMENTS_ERROR));
    }
  }

  getPanelWidths() {
    const [leftWidth, rightWidth] = this.widthRatio.split('/');

    return {
      leftPanelWidth: `${leftWidth}%`,
      rightPanelWidth: `${rightWidth}%`,
    };
  }

  __buildTags(documents = []) {
    return documents.reduce((acc, cur) => {
      if (!cur) return acc;
      if (!cur.documentTags.length) return acc;

      cur.documentTags.forEach(documentTag => {
        acc[documentTag.tagId] = {
          id: documentTag.tagId,
          documentId: documentTag.documentId,
          name: documentTag.tag.name,
          color: documentTag.tag.color,
        };
      });

      return acc;
    }, {});
  }

  __clearSelectedDocument() {
    this.__selectedDocument = null;
    this.__imageSrcSpec = null;
  }

  __renderEncounterTab() {
    return html`
      <neb-select
        id="${ELEMENTS.encountersSelect.id}"
        label="Service Date"
        class="service-date-select"
        .items="${this.__encounters}"
        .value="${this.__selectedEncounter}"
        .onChange="${this.handlers.onChangeEncounter}"
        .layout="${this.layout}"
      ></neb-select>

      <neb-encounter-summary
        id="${ELEMENTS.encounterSummary.id}"
        class="encounter-summary"
        .model="${this.__summaryModel}"
      >
      </neb-encounter-summary>
    `;
  }

  __renderDocumentsTab() {
    return !this.__selectedDocument && !this.__imageSrcSpec
      ? html`
          <div class="document-container">
            <neb-documents-filters
              id="${ELEMENTS.documentsFilters.id}"
              class="documents-filters"
              .model="${this.__filters}"
              .tags="${Object.values(this.__tags)}"
              .onChange="${this.handlers.onFilterDocuments}"
            >
            </neb-documents-filters>

            <neb-documents-tile-view
              id="${ELEMENTS.documentsTileView.id}"
              .documents="${this.__documents}"
              .onSelectDocument="${this.handlers.selectDocument}"
            ></neb-documents-tile-view>
          </div>
        `
      : html`
          <neb-page-document-viewer
            id="${ELEMENTS.documentViewer.id}"
            .selectedDocument="${this.__selectedDocument}"
            .imageSrcSpec="${this.__imageSrcSpec}"
            .onClose="${this.handlers.closeDocument}"
          ></neb-page-document-viewer>
        `;
  }

  __renderHeader() {
    return html`
      <div class="header">
        <div class="header-text">
          <span id="${ELEMENTS.claimNumberHeader.id}" class="header-title"
            >Claim Number: ${this.model.claimNumber}</span
          >
        </div>

        <neb-icon
          id="${ELEMENTS.cancelButton.id}"
          class="icon"
          icon="neb:close"
          @click="${this.handlers.dismiss}"
        ></neb-icon>
      </div>
    `;
  }

  __renderPanels() {
    const { leftPanelWidth } = this.getPanelWidths();

    return html`
      <div class="panels">
        <div class="left-panel" style="${styleMap({ width: leftPanelWidth })}">
          ${this.__renderLeftPanel()}
        </div>
        ${this.__renderRightPanel()}
      </div>
    `;
  }

  __renderRightPanel() {
    const { rightPanelWidth } = this.getPanelWidths();

    return html`
      <div class="right-panel" style="${styleMap({ width: rightPanelWidth })}">
        ${this.__renderClaimInfo()} ${this.renderForm()}
      </div>
    `;
  }

  __renderClaimInfoContainer() {
    return html`
      <div class="claim-info-container">
        <div class="field">
          <div id="${ELEMENTS.patientName.id}" class="ellipse-field">
            <neb-text class="label">Patient: </neb-text>
            ${objToName(this.model.patient.name, NAME_OPTS)}
          </div>
          <div id="${ELEMENTS.provider.id}" class="ellipse-field">
            <neb-text class="label">Provider: </neb-text>
            ${objToName(this.model.provider.name, NAME_OPTS)}
          </div>
          <div id="${ELEMENTS.payer.id}">
            <neb-text class="label">Payer: </neb-text> ${this.model.payer}
          </div>
        </div>
        <div class="field">
          <div id="${ELEMENTS.serviceDate.id}">
            <neb-text class="label">Service Date: </neb-text> ${
              this.model.serviceDate
            }
          </div>
          <div id="${ELEMENTS.lastUpdated.id}">
            <neb-text class="label">Last Updated: </neb-text> ${
              this.model.lastUpdated
            }
          </div>
        </div>
      </div>
    `;
  }

  __renderIcon() {
    return html`
      <neb-icon
        id="${ELEMENTS.warningIcon.id}"
        class="icon-warning"
        icon="neb:warning"
      ></neb-icon>
    `;
  }

  __renderErrors() {
    const errors = this.model.errorDescriptions.map(
      err =>
        html`
          <div class="error-description cell">${err}</div>
        `,
    );

    return html`
      <div class="error-table" id="${ELEMENTS.errorTable.id}">
        <div class="error-header cell">
          ${this.__renderIcon()}Validation Errors
        </div>
        ${errors}
      </div>
    `;
  }

  __renderClaimInfo() {
    return html`
      <div class="claim-info" id="${ELEMENTS.claimInfo.id}">
        ${this.__renderClaimInfoContainer()} ${this.__renderErrors()}
      </div>
    `;
  }

  __renderLeftPanel() {
    return this.__hasDocumentsTabFF
      ? html`
          <neb-tab-group
            id="${ELEMENTS.leftPanelTabs.id}"
            class="left-panel-tabs"
            .items="${this.__leftPanelTabs}"
            .selectedId="${this.__selectedLeftPanelTab}"
            .onSelect="${this.handlers.selectLeftPanelTab}"
          ></neb-tab-group>
          ${
            this.__selectedLeftPanelTab === 'encounter'
              ? this.__renderEncounterTab()
              : this.__renderDocumentsTab()
          }
        `
      : html`
          ${this.__renderEncounterTab()}
        `;
  }

  renderForm() {
    return html`
      <div class="form" id="${ELEMENTS.form.id}"></div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderPanels()}
    `;
  }
}

customElements.define('neb-overlay-claim-error', NebOverlayClaimError);
