import '../../../../neb-lit-components/src/components/neb-button';
import '../../../../../src/components/misc/neb-icon';
import '../../../../neb-lit-components/src/components/neb-rich-text-editor';
import '../../../../neb-lit-components/src/components/neb-rich-text-viewer';
import '../../../../neb-material-design/src/components/neb-loading-spinner';
import '../../../../../src/components/misc/neb-structured-document-viewer';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../../../../neb-lit-components/src/components/controls/neb-menu-button';
import '../../../../neb-lit-components/src/components/neb-dropdown';

import { openPopup } from '@neb/popup';
import cheerio from 'cheerio';
import { LitElement, html, css } from 'lit';

import { UNSUPPORTED_ADVANCED_TOKENS } from '../../../../../src/utils/macros';
import { fetchNotes } from '../../../../neb-api-client/src/notes';
import { reset as resetSessionTimer } from '../../../../neb-login/neb-timeout-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { ProviderService } from '../../../../neb-redux/services/provider';
import {
  CSS_SPACING_ROW_LARGE,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_9,
  CSS_COLOR_GREY_10,
  CSS_COLOR_OVERLAY_LOADING,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import { TAB_KEYS } from '../../../../neb-utils/macro-sets';
import {
  formatEncounterCardDropdown,
  formatEncounter,
  NO_PRIOR_ENCOUNTERS_MESSAGE,
} from '../../../../neb-utils/neb-charting-util';

import {
  MODE_OVERLAY,
  initNotesState,
  parseQuestions,
  parseFields,
} from './neb-charting-notes-util';

export const ELEMENTS = {
  richTextEditor: {
    id: 'neb-rich-text-editor',
  },
  priorEncounterDropdown: {
    id: 'prior-encounter-dropdown',
  },
  priorEncounterNoteView: {
    id: 'prior-encounter-note-viewer',
  },
  documentsDropdown: {
    id: 'document-dropdown',
  },
  documentViewer: {
    id: 'document-viewer',
  },
  draggableDivider: {
    id: 'draggable-divider',
  },
  noteContainer: {
    id: 'note-container',
  },
  copyNoteButton: {
    id: 'copy-note-button',
  },
  currentEncounter: {
    id: 'current-encounter',
  },
  currentEncounterText: {
    id: 'current-encounter-text',
  },
  priorEncounterNoteContainer: {
    id: 'prior-encounter-container',
  },
  patientSubjectiveButton: {
    id: 'patient-subjective-button',
  },
  priorEncounterCloseButton: {
    id: 'close-button',
  },
  overlayText: {
    id: 'overlay-text',
  },
  checkboxHideUnanswered: {
    id: 'checkbox-hide-unanswered',
  },
};
export const PARSED_NONBREAKABLE_SPACE = '<p>&nbsp;</p>';
export const STATIC_HEADERS = {
  [TAB_KEYS.SUBJECTIVE]:
    '<p contenteditable="false"><strong>SUBJECTIVE</strong></p>',
  [TAB_KEYS.OBJECTIVE]:
    '<p contenteditable="false"><strong>OBJECTIVE</strong></p>',
  [TAB_KEYS.ASSESSMENT]:
    '<p contenteditable="false"><strong>ASSESSMENT</strong></p>',
  [TAB_KEYS.PLAN]: '<p contenteditable="false"><strong>PLAN</strong></p>',
};
export const LINE_SEPARATOR = '\n';
export const PRIOR_ENCOUNTER_DROPDOWN_PLACEHOLDER = 'Compare Prior Encounter';

class NebChartingNotesEditorController extends LitElement {
  static get properties() {
    return {
      __hideUnanswered: Boolean,
      __providers: Array,
      font: Object,
      currentText: String,
      docsEnabled: Boolean,
      documents: Array,
      dragging: {
        type: Boolean,
        reflect: true,
      },
      expanded: Boolean,
      encounter: Object,
      layout: {
        type: String,
        reflect: true,
      },
      notes: Object,
      overlayMode: String,
      priorEncounterNotes: Object,
      priorEncounters: Array,
      currentEncounterApptsWithNarratives: Array,
      selectedDocument: Object,
      selectedPriorEncounter: Object,
      selectedTab: String,
      showNoteLoadingIndicator: Boolean,
      isEditorReady: Boolean,
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      :host([dragging]) {
        cursor: grabbing;
      }

      .note-header-container {
        display: flex;
        justify-content: space-between;
        flex-shrink: 0; /* Needed for safari */
        margin: 0 45px 10px 27px;
      }

      :host(:not([layout='large'])) .note-header-container {
        flex-direction: column;
        gap: 5px;
      }

      .current-encounter > span:first-child,
      .prior-encounter-text > span:first-child {
        font-weight: bold;
      }

      .prior-encounter-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
        display: inline-block;
      }

      .prior-encounter-header-right {
        display: flex;
        align-items: center;
      }

      .structured-viewer {
        min-width: 315px;
        overflow-y: auto;
        border-top: 1px solid ${CSS_COLOR_GREY_2};
      }

      .dropdown-container {
        display: flex;
        align-items: center;
        gap: ${CSS_SPACING_ROW_LARGE};
      }

      .dropdown {
        width: 350px;
      }

      :host([layout='large']) .dropdown {
        justify-content: flex-end;
        margin-left: 12px;
        flex: 1 1 0px;
      }

      .notes-container {
        display: flex;
        height: 100%;
        min-height: 0;
      }

      .flex-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 0;
      }

      .editor {
        flex: 50 0 0;
        min-width: 315px;
        min-height: 220px;
      }

      .viewer {
        display: flex;
        flex-direction: column;
        flex: 50 0 0;
        position: relative;
        min-width: 315px;
      }

      .draggable-divider {
        width: 8px;
        height: 100%;
        background-color: ${CSS_COLOR_GREY_2};
        cursor: grab;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .draggable-divider[dragging] {
        cursor: grabbing;
      }

      .copy-note-button {
        min-height: 30px;
        height: 100%;
      }

      .handle-circle {
        margin-top: 2px;
        width: 6px;
        height: 6px;
        background: ${CSS_COLOR_WHITE};
        border-radius: 50%;
      }

      .prior-encounter-header {
        border-top: 1px solid ${CSS_COLOR_GREY_9};
        border-left: 1px solid ${CSS_COLOR_GREY_9};
        border-right: 1px solid ${CSS_COLOR_GREY_9};
        border-radius: 2px 2px 0 0;
        background-color: ${CSS_COLOR_GREY_10};
        margin: 0;
        padding: 7px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0; /* Needed for safari */
      }

      .icon-close {
        cursor: pointer;
        display: flex;
        width: 24px;
        height: 24px;
        fill: ${CSS_COLOR_GREY_1};
      }

      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${CSS_COLOR_OVERLAY_LOADING};
        z-index: 1;
      }

      .hide-unanswered-checkbox {
        width: 155px;
        margin-left: 12px;
        margin-right: 12px;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.__hideUnanswered = false;
    this.__providers = [];

    this.currentText = '';
    this.docsEnabled = false;
    this.documents = [];
    this.dragging = false;
    this.encounter = {
      id: '',
    };

    this.font = {
      defaultFontFamily: 'Helvetica',
      defaultFontSize: '16',
    };

    this.expanded = false;
    this.layout = '';
    this.notes = initNotesState();
    this.overlayMode = MODE_OVERLAY.NONE;
    this.priorEncounterNotes = initNotesState();
    this.priorEncounters = [];
    this.currentEncounterApptsWithNarratives = [];
    this.selectedDocument = {};
    this.selectedPriorEncounter = {};
    this.selectedTab = TAB_KEYS.SUBJECTIVE;
    this.showNoteLoadingIndicator = false;
    this.isEditorReady = false;

    this.onNoteChange = () => {};

    this.onSelectQuestion = () => {};

    this.onSelectField = () => {};

    this.onSectionChanged = () => {};

    this.onNotesSynced = () => {};

    this.unsupportedFieldSelected = () => {};
  }

  __initServices() {
    this.__providerService = new ProviderService(({ providers }) => {
      this.__providers = providers;
    });
  }

  __initHandlers() {
    this.__handlers = {
      textChanged: value => {
        if (typeof value === 'string') {
          this.currentText = value;
          const tabKey = this.selectedTab;

          if (
            this.isSingleSoapTab(tabKey) ||
            (!this.isSingleSoapTab(tabKey) && !this.isEditorReady)
          ) {
            this.__handleNoteUpdates({
              [tabKey]: value.trim(),
            });
          } else {
            this.__parseSOAPText(value);
          }

          this.onNotesSynced();
        }
      },

      linkClicked: ({ index, dataset }) => {
        const $ = cheerio.load(this.getActiveNote(this.notes));

        const linkSelector =
          'span[contenteditable="false"], div[contenteditable="false"]';

        const skipLinksWithAttr = [
          dataset.question ? 'data-field' : 'data-question',
          'data-macro-replace-field',
        ];

        const linkOffset = Array.from($(linkSelector)).reduce(
          (offset, link, i) =>
            i < index && skipLinksWithAttr.find(attr => $(link).attr(attr))
              ? offset - 1
              : offset,
          0,
        );

        const elements = dataset.question
          ? parseQuestions(this.notes)
          : parseFields(this.notes);

        const previousTabsOffset =
          this.selectedTab !== TAB_KEYS.SOAP
            ? elements.findIndex(({ section }) => section === this.selectedTab)
            : 0;

        const adjustedIndex = index + previousTabsOffset + linkOffset;

        if (dataset.question) {
          this.onSelectQuestion(adjustedIndex);
        } else if (
          Object.keys(UNSUPPORTED_ADVANCED_TOKENS).includes(
            dataset.macroReplaceField,
          )
        ) {
          this.onSelectUnsupportedField();
        } else {
          this.onSelectField(adjustedIndex);
        }
      },

      priorEncounterSelected: async ({ selectedItem }) => {
        this.selectedPriorEncounter = selectedItem;
        this.overlayMode = MODE_OVERLAY.LOADING_NOTES;
        this.priorEncounterNotes = await fetchNotes(
          this.priorEncounterNotes,
          selectedItem.value,
        );

        this.overlayMode = MODE_OVERLAY.NONE;
      },

      documentSelected: ({ selectedItem }) => {
        this.selectedDocument = selectedItem;
      },

      mouseDown: e => {
        this.dragging = true;
        this.__elements.richTextEditor.style.pointerEvents = 'none';
        this.__elements.noteContainer.style.pointerEvents = 'none';
        e.preventDefault();

        const unregister = _e => {
          this.__elements.richTextEditor.style.pointerEvents = 'initial';
          this.__elements.noteContainer.style.pointerEvents = 'initial';
          this.removeEventListener('mousemove', this.__handleResize);
          this.dragging = false;
          document.removeEventListener('mouseup', unregister);
        };

        this.addEventListener('mousemove', this.__handleResize);
        document.addEventListener('mouseup', unregister);
      },

      copyPriorEncounterNote: async () => {
        if (this.selectedTab === TAB_KEYS.SOAP) {
          return this.__copySoapNotes();
        }

        if (this.__isNoteEmpty(this.notes)) {
          return this.onNoteChange(
            this.selectedTab,
            this.priorEncounterNotes[this.selectedTab],
          );
        }

        const action = await this.__openPopup();

        switch (action) {
          case 'replace':
            this.onNoteChange(
              this.selectedTab,
              this.priorEncounterNotes[this.selectedTab],
            );

            break;

          case 'append':
            this.onNoteChange(
              this.selectedTab,
              this.notes[this.selectedTab].concat(
                LINE_SEPARATOR,
                this.priorEncounterNotes[this.selectedTab],
              ),
            );

            break;

          default:
        }

        return undefined;
      },

      copyDocumentData: pairs => {
        const separateAnswers = (arr, acc) => {
          arr.forEach((el, i) => {
            acc.push(`${el}`);

            if (i < arr.length - 1) {
              acc.push('<br />');
            }
          });
        };

        const res = pairs.reduce((acc, pair) => {
          acc.push(`<p><b>${pair.question}</b>`);

          if (Array.isArray(pair.answer[0])) {
            const nonTableAnswers = pair.answer.filter(
              ans => !Array.isArray(ans),
            );

            acc.push('<table><tbody>');

            pair.answer.forEach((ans, index) => {
              if (Array.isArray(ans)) {
                acc.push('<tr>');

                ans.forEach((subAns, idx) => {
                  acc.push('<td>');

                  if (Array.isArray(subAns)) {
                    separateAnswers(subAns, acc);
                  } else if (index === 0 || idx === 0) {
                    acc.push(`<b>${subAns}</b>`);
                  } else {
                    acc.push(`${subAns}`);
                  }

                  acc.push('</td>');
                });

                acc.push('</tr>');
              }
            });

            acc.push('</tbody></table>');

            if (nonTableAnswers) {
              separateAnswers(nonTableAnswers, acc);
            }
          } else if (Array.isArray(pair.answer)) {
            pair.answer.forEach(ans => {
              acc.push(`<br />${ans}`);
            });
          } else {
            acc.push(`<br />${pair.answer}`);
          }

          acc.push('</p><p></p>');
          return acc;
        }, []);

        const copiedNotesWithDefaultFont = res
          .join('')
          .replace(/<p>(.*?)<\/p>/g, '<p><span style="">$1</span></p>');

        this.notes[this.selectedTab] = `${
          this.notes[this.selectedTab]
        }${copiedNotesWithDefaultFont}`;

        this.onNoteChange(this.selectedTab, this.notes[this.selectedTab]);
      },

      sectionChanged: sectionIndex => {
        this.onSectionChanged(Object.values(TAB_KEYS)[sectionIndex]);
      },

      closePriorEncounter: () => {
        this.selectedPriorEncounter = {};
      },

      closeDocument: () => {
        this.selectedDocument = {};
      },

      updateHideUnanswered: e => {
        this.__hideUnanswered = e.value;
      },

      selectSubjectiveQuestionnaire: async ({ value: { narrative } }) => {
        const strategy = await openPopup(
          POPUP_RENDER_KEYS.SUBJECTIVE_QUESTIONNAIRE_APPEND_REPLACE,
          { narrative },
        );

        narrative = narrative.replace(
          /<p>(.*?)<\/p>/g,
          '<p><span style="">$1</span></p>',
        );

        switch (strategy) {
          case 'append': {
            this.onNoteChange(
              'subjective',
              `${this.notes.subjective}${narrative}`,
            );

            break;
          }

          case 'replace': {
            this.onNoteChange('subjective', `${narrative}`);
            break;
          }
          default:
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.__providerService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__providerService.disconnect();
  }

  __handleNoteUpdates(noteUpdates) {
    const hasChange = Object.entries(noteUpdates).some(
      ([key, value]) => value !== this.notes[key],
    );

    if (hasChange) {
      store.dispatch(resetSessionTimer());
      Object.entries(noteUpdates).forEach(([key, value]) =>
        this.onNoteChange(key, value),
      );
    } else if (this.selectedTab !== 'soap') {
      this.__elements.richTextEditor.sync();
    }
  }

  async __copySoapNotes() {
    const { toCopy } = await openPopup(POPUP_RENDER_KEYS.SOAP_NOTE_COPY, {
      notes: this.priorEncounterNotes,
    });
    const hasConflictingNotes = toCopy.some(
      key => this.notes[key] && this.notes[key].length > 0,
    );
    const strategy = hasConflictingNotes
      ? (await openPopup(POPUP_RENDER_KEYS.SOAP_NOTE_APPEND_REPLACE)).action
      : 'replace';
    if (strategy === 'cancel') return;
    toCopy.forEach(key => {
      if (strategy === 'replace') {
        this.onNoteChange(key, this.priorEncounterNotes[key]);
      } else {
        this.onNoteChange(
          key,
          `${this.notes[key]}${this.priorEncounterNotes[key]}`,
        );
      }
    });
  }

  __handleResize({ movementX }) {
    const parentWidth = this.__elements.noteContainer
      ? parseFloat(getComputedStyle(this.__elements.noteContainer, '').width)
      : 0;
    const editorWidth = this.__elements.richTextEditor
      ? parseFloat(getComputedStyle(this.__elements.richTextEditor, '').width)
      : 0;
    const dividerWidth = this.__elements.draggableDivider
      ? parseFloat(getComputedStyle(this.__elements.draggableDivider, '').width)
      : 0;
    const newEditorWidth = editorWidth + movementX;
    const editorWidthRatio = newEditorWidth / (parentWidth - dividerWidth);
    const editorWidthPercentage = editorWidthRatio * 100;

    this.__resize(editorWidthPercentage);
  }

  __resize(percent) {
    this.__elements.richTextEditor.style.flexGrow = percent;
    this.__elements.encounterView.style.flexGrow = 100 - percent;
  }

  __openPopup() {
    return openPopup(POPUP_RENDER_KEYS.CHARTING_NOTE_COPY, {
      title: 'Append or Replace Prior Encounter Note',
      message:
        "The chart note for today's encounter already has content in one or more sections. Would you like to append the copied content into the existing note, or replace the content of the existing note?",
    });
  }

  reset() {
    this.selectedPriorEncounter = {};
  }

  __parseSOAPText(text) {
    const subjectiveIndex = text.indexOf(STATIC_HEADERS[TAB_KEYS.SUBJECTIVE]);
    const subjectiveLength = STATIC_HEADERS[TAB_KEYS.SUBJECTIVE].length;
    const objectiveIndex = text.indexOf(STATIC_HEADERS[TAB_KEYS.OBJECTIVE]);
    const objectiveLength = STATIC_HEADERS[TAB_KEYS.OBJECTIVE].length;
    const assessmentIndex = text.indexOf(STATIC_HEADERS[TAB_KEYS.ASSESSMENT]);
    const assessmentLength = STATIC_HEADERS[TAB_KEYS.ASSESSMENT].length;
    const planIndex = text.indexOf(STATIC_HEADERS[TAB_KEYS.PLAN]);
    const planLength = STATIC_HEADERS[TAB_KEYS.PLAN].length;
    const subjectiveNote = text
      .slice(subjectiveIndex + subjectiveLength, objectiveIndex)
      .trim();
    const objectiveNote = text
      .slice(objectiveIndex + objectiveLength, assessmentIndex)
      .trim();
    const assessmentNote = text
      .slice(assessmentIndex + assessmentLength, planIndex)
      .trim();
    const planNote = text.slice(planIndex + planLength).trim();

    if (!subjectiveNote || !objectiveNote || !assessmentNote || !planNote) {
      setTimeout(() => this.__elements.richTextEditor.sync(), 0);
    }

    this.__handleNoteUpdates({
      [TAB_KEYS.SUBJECTIVE]:
        subjectiveNote === PARSED_NONBREAKABLE_SPACE ? '' : subjectiveNote,
      [TAB_KEYS.OBJECTIVE]:
        objectiveNote === PARSED_NONBREAKABLE_SPACE ? '' : objectiveNote,
      [TAB_KEYS.ASSESSMENT]:
        assessmentNote === PARSED_NONBREAKABLE_SPACE ? '' : assessmentNote,
      [TAB_KEYS.PLAN]: planNote === PARSED_NONBREAKABLE_SPACE ? '' : planNote,
    });
  }

  isSingleSoapTab(tabKey) {
    switch (tabKey) {
      case TAB_KEYS.SOAP:
      case TAB_KEYS.DATA:
        return false;

      default:
        return true;
    }
  }

  getActiveNote(notes) {
    switch (this.selectedTab) {
      case TAB_KEYS.SOAP:
        return [
          STATIC_HEADERS[TAB_KEYS.SUBJECTIVE],
          notes[TAB_KEYS.SUBJECTIVE] || PARSED_NONBREAKABLE_SPACE,
          STATIC_HEADERS[TAB_KEYS.OBJECTIVE],
          notes[TAB_KEYS.OBJECTIVE] || PARSED_NONBREAKABLE_SPACE,
          STATIC_HEADERS[TAB_KEYS.ASSESSMENT],
          notes[TAB_KEYS.ASSESSMENT] || PARSED_NONBREAKABLE_SPACE,
          STATIC_HEADERS[TAB_KEYS.PLAN],
          notes[TAB_KEYS.PLAN] || PARSED_NONBREAKABLE_SPACE,
        ].join(LINE_SEPARATOR);

      case TAB_KEYS.DATA:
        return '';

      default:
        const parsedNote = notes[this.selectedTab];
        return parsedNote.trim();
    }
  }

  async setLocalState(payload) {
    if (payload !== null) {
      this.selectedPriorEncounter = payload.priorEncounter;
      this.priorEncounterNotes = payload.priorEncounterNotes;
      await this.updateComplete;

      this.__resize(payload.flexGrow);
    }
  }

  getLocalState() {
    if (!this.__elements) {
      return undefined;
    }

    const rteElement = this.__elements.richTextEditor;
    return this.selectedPriorEncounter.displayValue
      ? {
          priorEncounter: this.selectedPriorEncounter,
          priorEncounterNotes: this.priorEncounterNotes,
          rteGrow: rteElement ? rteElement.style.flexGrow || 50 : 0,
        }
      : null;
  }

  updated(changedProps) {
    if (!changedProps.has('__safariNonsense')) {
      this.__safariNonsense = !this.__safariNonsense;
    }

    this.__elements = {
      richTextEditor: this.shadowRoot.getElementById(
        ELEMENTS.richTextEditor.id,
      ),
      encounterView: this.shadowRoot.getElementById(
        ELEMENTS.priorEncounterNoteContainer.id,
      ),
      draggableDivider: this.shadowRoot.getElementById(
        ELEMENTS.draggableDivider.id,
      ),
      noteContainer: this.shadowRoot.getElementById(ELEMENTS.noteContainer.id),
    };

    super.updated(changedProps);
  }

  __isNoteEmpty(notes) {
    if (this.selectedTab === TAB_KEYS.SOAP) {
      return (
        notes[TAB_KEYS.SUBJECTIVE] === '' &&
        notes[TAB_KEYS.OBJECTIVE] === '' &&
        notes[TAB_KEYS.ASSESSMENT] === '' &&
        notes[TAB_KEYS.PLAN] === ''
      );
    }

    return notes[this.selectedTab] === '';
  }

  __isCopyButtonDisabled() {
    return this.__isNoteEmpty(this.priorEncounterNotes);
  }

  insertText(text) {
    this.__elements.richTextEditor.insertText(text);
  }

  __renderRichTextEditor() {
    return html`
      <neb-rich-text-editor
        id="${ELEMENTS.richTextEditor.id}"
        class="editor"
        .onChange="${this.__handlers.textChanged}"
        .value="${this.getActiveNote(this.notes)}"
        .font="${this.font}"
        .key="${this.selectedTab}"
        .onLinkClick="${this.__handlers.linkClicked}"
        .onSectionChanged="${this.__handlers.sectionChanged}"
      ></neb-rich-text-editor>
    `;
  }

  __renderHandle() {
    return html`
      <div class="handle-circle"></div>
      <div class="handle-circle"></div>
      <div class="handle-circle"></div>
    `;
  }

  __renderPriorEncounterData() {
    const priorEncounter = this.priorEncounters.find(
      encounter => encounter.id === this.selectedPriorEncounter.value,
    );

    if (!priorEncounter) {
      return '';
    }

    return `
      ${priorEncounter.formattedServiceDate} ${
      priorEncounter.formattedServiceTime
    } - ${priorEncounter.provider} -
      ${priorEncounter.appointmentType}
    `;
  }

  __renderOverlay() {
    return this.overlayMode === MODE_OVERLAY.LOADING_NOTES
      ? html`
          <div class="overlay">
            <neb-loading-spinner></neb-loading-spinner>
            <p id="${ELEMENTS.overlayText.id}" class="text-loading">
              Loading Notes...
            </p>
          </div>
        `
      : '';
  }

  __renderPriorEncounterNoteView() {
    return html`
      <div id="${ELEMENTS.priorEncounterNoteContainer.id}" class="viewer">
        <div class="prior-encounter-header">
          <div class="prior-encounter-text">
            <span>Prior Encounter: </span>

            <span> ${this.__renderPriorEncounterData()} </span>
          </div>

          <neb-icon
            id="${ELEMENTS.priorEncounterCloseButton.id}"
            class="icon icon-close"
            icon="neb:close"
            @click="${this.__handlers.closePriorEncounter}"
          ></neb-icon>
        </div>

        <neb-rich-text-viewer
          id="${ELEMENTS.priorEncounterNoteView.id}"
          .key="${this.selectedTab}"
          .value="${this.getActiveNote(this.priorEncounterNotes)}"
          .onSectionChanged="${this.__handlers.sectionChanged}"
        ></neb-rich-text-viewer>

        ${this.__renderOverlay()}
      </div>
    `;
  }

  __renderDocumentDataView() {
    return html`
      <div id="${ELEMENTS.priorEncounterNoteContainer.id}" class="viewer">
        <div class="prior-encounter-header">
          <div class="prior-encounter-text">Completed Questionnaire</div>

          <div class="prior-encounter-header-right">
            <neb-checkbox
              id="${ELEMENTS.checkboxHideUnanswered.id}"
              class="hide-unanswered-checkbox"
              .onChange="${this.__handlers.updateHideUnanswered}"
              label="Hide Unanswered"
              ?checked="${this.__hideUnanswered}"
            >
            </neb-checkbox>

            <neb-icon
              id="${ELEMENTS.priorEncounterCloseButton.id}"
              class="icon icon-close"
              icon="neb:close"
              @click="${this.__handlers.closeDocument}"
            ></neb-icon>
          </div>
        </div>

        <neb-structured-document-viewer
          id="${ELEMENTS.documentViewer.id}"
          class="structured-viewer"
          .document="${this.selectedDocument.value}"
          .onCopy="${this.__handlers.copyDocumentData}"
          .hideUnanswered="${this.__hideUnanswered}"
          .isSOAPTabSelected="${this.selectedTab === TAB_KEYS.SOAP}"
        ></neb-structured-document-viewer>

        ${this.__renderOverlay()}
      </div>
    `;
  }

  __renderEncounterNoteView() {
    return html`
      <div
        id="${ELEMENTS.draggableDivider.id}"
        class="draggable-divider"
        ?dragging="${this.dragging}"
        @mousedown="${this.__handlers.mouseDown}"
      >
        ${this.__renderHandle()}
      </div>

      ${this.__renderPriorEncounterNoteView()}
    `;
  }

  __renderDocumentView() {
    return html`
      <div
        id="${ELEMENTS.draggableDivider.id}"
        class="draggable-divider"
        ?dragging="${this.dragging}"
        @mousedown="${this.__handlers.mouseDown}"
      >
        ${this.__renderHandle()}
      </div>

      ${this.__renderDocumentDataView()}
    `;
  }

  __getCopyNoteButtonLabel() {
    return this.selectedTab === TAB_KEYS.SOAP
      ? 'COPY SOAP NOTE'
      : `COPY ${this.selectedTab}`;
  }

  __renderCopyNoteButton() {
    return this.selectedPriorEncounter.displayValue
      ? html`
          <neb-button
            id="${ELEMENTS.copyNoteButton.id}"
            class="copy-note-button"
            role="outline"
            ?disabled="${this.__isCopyButtonDisabled()}"
            .label="${this.__getCopyNoteButtonLabel()}"
            .onClick="${this.__handlers.copyPriorEncounterNote}"
          ></neb-button>
        `
      : '';
  }

  __renderCurrentEncounter() {
    const { appointmentTypes } = store.getState();

    if (
      !this.encounter.id ||
      this.__providers.length === 0 ||
      !appointmentTypes ||
      appointmentTypes.items.length === 0
    ) {
      return '';
    }

    if (this.__providers && appointmentTypes.items) {
      const currentEncounter = formatEncounter(
        this.encounter,
        this.__providers,
        appointmentTypes.items,
      );

      const encounterProvider = this.__providers.find(
        provider => provider.id === currentEncounter.providerId,
      );

      this.font.defaultFontFamily = encounterProvider.richTextFontFamily;
      this.font.defaultFontSize = encounterProvider.richTextFontSize;

      return html`
        <span id="${ELEMENTS.currentEncounterText.id}">
          ${currentEncounter.fullDate} - ${currentEncounter.provider} -
          ${currentEncounter.appointmentType}
        </span>
      `;
    }

    return '';
  }

  __renderDocumentsDropdown() {
    return !this.selectedPriorEncounter.displayValue && this.docsEnabled
      ? html`
          <neb-dropdown
            id="${ELEMENTS.documentsDropdown.id}"
            class="dropdown"
            placeholder="Select Documents"
            emptyMessage="There are no structured documents for this patient."
            .items="${this.documents}"
            .onItemSelected="${this.__handlers.documentSelected}"
            .selectedItem="${this.selectedDocument}"
          ></neb-dropdown>
        `
      : '';
  }

  __formatAppointments() {
    return this.currentEncounterApptsWithNarratives.map(appointment => ({
      label: `${appointment.startTime} - ${appointment.providerName} - ${
        appointment.appointmentTypeName
      }`,
      narrative: appointment.selfCheckInQuestionnaireNarrative,
    }));
  }

  __renderPatientSubjectiveMenuButton() {
    return this.selectedTab === TAB_KEYS.SUBJECTIVE &&
      this.currentEncounterApptsWithNarratives.length > 0
      ? html`
          <neb-menu-button
            id="${ELEMENTS.patientSubjectiveButton.id}"
            label="Patient Subjective"
            .items="${this.__formatAppointments()}"
            .onSelect="${this.__handlers.selectSubjectiveQuestionnaire}"
          >
          </neb-menu-button>
        `
      : '';
  }

  __renderPriorEncounterDropdown() {
    return !this.selectedDocument.displayValue
      ? html`
          <neb-dropdown
            id="${ELEMENTS.priorEncounterDropdown.id}"
            class="dropdown"
            .placeholder="${PRIOR_ENCOUNTER_DROPDOWN_PLACEHOLDER}"
            .items="${formatEncounterCardDropdown(this.priorEncounters)}"
            .emptyMessage="${NO_PRIOR_ENCOUNTERS_MESSAGE}"
            .onItemSelected="${this.__handlers.priorEncounterSelected}"
            .selectedItem="${this.selectedPriorEncounter}"
          ></neb-dropdown>
        `
      : '';
  }

  render() {
    return html`
      <div class="flex-container">
        <div class="note-header-container">
          <span id="${ELEMENTS.currentEncounter.id}" class="current-encounter">
            <span>Current Encounter: </span> ${this.__renderCurrentEncounter()}
          </span>
          <div class="dropdown-container">
            ${this.__renderPatientSubjectiveMenuButton()}
            ${this.__renderDocumentsDropdown()} ${this.__renderCopyNoteButton()}
            ${this.__renderPriorEncounterDropdown()}
          </div>
        </div>

        <div id="${ELEMENTS.noteContainer.id}" class="notes-container">
          ${this.expanded && this.__renderRichTextEditor()}
          ${
            this.selectedPriorEncounter.displayValue &&
              this.__renderEncounterNoteView()
          }
          ${this.selectedDocument.displayValue && this.__renderDocumentView()}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-charting-notes-editor-controller',
  NebChartingNotesEditorController,
);
