import { html } from 'lit';

import '../../../../packages/neb-lit-components/src/components/forms/encounter/neb-form-manage-encounter';
import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';
import '../../controllers/claim-errors/neb-manage-encounter-controller';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  manageEncounterController: { id: 'manage-encounter-controller' },
};

class NebOverlayClaimErrorManageEncounter extends NebOverlayClaimError {
  static get properties() {
    return {};
  }

  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();
  }

  __reformatModel() {
    const invoiceEncounters = this.model.claim.claimCharges.map(
      charge => charge.encounterId,
    );

    return { encounterIds: invoiceEncounters, patientId: this.model.patientId };
  }

  renderForm() {
    return html`
      <neb-manage-encounter-controller
        id="${ELEMENTS.manageEncounterController.id}"
        .model="${this.__reformatModel()}"
        .onCancel="${this.handlers.dismiss}"
        .onDismiss="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .hideEncounterTableDetails="${true}"
      ></neb-manage-encounter-controller>
    `;
  }
}

customElements.define(
  'neb-overlay-claim-error-manage-encounter',
  NebOverlayClaimErrorManageEncounter,
);
